import { reactive } from "vue";

const store = {
    state: reactive({
        user: null,
    }),
    getters: {
        getUser() {
            return store.state.user;
        },
        getUserId() {
            return store.state.user.dipendenti_user_id || null;
        },
        getDipendenteId() {
            return store.state.user.dipendenti_id || null;
        },
    },
    mutations: {
        setUser(user) {
            store.state.user = user;
        },
        resetUser() {
            store.state.user = null;
        },
    },
    actions: {
        initializeUser() {
            console.log("initializing user...");
            store.state.user = JSON.parse(localStorage.getItem("userPlanInfo")) || null;
        },
    },
};

export default store;
